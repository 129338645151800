import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { colors } from '../entrypoints/lib/theme';

const AlertDialog = ({
  open,
  title,
  content,
  onClose,
  okText = 'OK',
  okButtonProps = {},
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} {...okButtonProps} variant="contained">
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
